.dashboard {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dashboard-column {
        display: flex;
        .select-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;
            .select-label {
                margin-right: 20px;
            }
            .select {
                min-width: 100px;
            }
        }
        .slider-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;
            width: 250px;
            .slider-label {
                margin-right: 10px;
            }
        }
    }
    .dashboard-button {
        margin: 10px;
    }
}